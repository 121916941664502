<template>
  <section
    class="homepage-hero"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
  >
    <div class="gradient" />
    <div ref="image" class="homepage-hero--image">
      <CustomImage :image="slice.primary.image" :sizes="[393, 1440]" />

      <!-- <video src="/hp-video.mp4" autoplay loop muted playsinline /> -->
    </div>
    <div class="title-container">
      <Logo ref="logo" class="homepage-hero--title hht" />
    </div>
  </section>
</template>

<script setup lang="ts">
import Logo from "@/assets/images/homepage/logo-homepage.svg?skipsvgo";
import { type Content } from "@prismicio/client";

import { gsap } from "gsap";

const logo = shallowRef(null);
const image = shallowRef(null);

let ctx;

onMounted(() => {
  initAnimations();
});

const initAnimations = () => {
  initSVGAnimation();
  initHeroParallax();
};

const initSVGAnimation = () => {
  const svgEl = logo.value;

  const letters = svgEl.$el.querySelectorAll(".svg-letter");

  ctx = gsap.context(() => {
    gsap.set(svgEl.$el, {
      autoAlpha: 1,
    });
    gsap.fromTo(
      letters,
      {
        yPercent: 120,
      },
      {
        yPercent: 0,
        stagger: {
          from: "start",
          amount: 0.2,
        },
        duration: 1.8,
        delay: 0.1,
        ease: "expo.out",
      }
    );
  });
};

const initHeroParallax = () => {
  ctx.add(() => {
    gsap.to(image.value, {
      yPercent: 15,
      ease: "none",
      scrollTrigger: {
        trigger: image.value,
        start: "top top",
        end: "bottom top",
        scrub: 0,
      },
    });
  });
};

onUnmounted(() => {
  ctx?.kill(false);
});

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
defineProps(
  getSliceComponentProps<Content.HomepageHeroSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ])
);
</script>

<style lang="scss" scoped>
.homepage-hero {
  position: relative;
  width: 100%;
  height: 100svh;
  overflow: hidden;
  padding: 0 var(--padding-mobile);
  z-index: 2;

  @include desktop {
    padding: 0 $padding-desktop;
  }

  .homepage-hero--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title-container {
  position: relative;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 1rem;
}

.homepage-hero--title {
  z-index: 1;
  width: 100%;
  visibility: hidden;
}

.homepage-hero--image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.38981530112044815) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  inset: 0;
  width: 100%;
  height: 10rem;
  z-index: 1;
  @include desktop {
    height: 20rem;
  }
}
</style>
